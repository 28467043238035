import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthenticationGuard } from "./core/auth/authentication.guard";

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },  
  { path: "login", loadChildren: () => import(`./login/login.module`).then((m) => m.LoginModule) },
  { path: "error", loadChildren: () => import(`./errorpages/errorpages.module`).then((m) => m.ErrorpagesModule) },

  { path: "dashboard", loadChildren: () => import(`./dashboard/dashboard.module`).then((m) => m.DashboardModule),canActivate: [AuthenticationGuard],},
  { path: "apps", loadChildren: () => import(`./apps/apps.module`).then((m) => m.AppsModule),canActivate: [AuthenticationGuard],},

  { path: "employees", loadChildren: () => import(`./employees/employees.module`).then((m) => m.EmployeesModule),canActivate: [AuthenticationGuard],},

  { path: "company", loadChildren: () => import(`./company/company.module`).then((m) => m.CompanyModule),canActivate: [AuthenticationGuard],},

  { path: "salary", loadChildren: () => import(`./salary/salary.module`).then((m) => m.SalaryModule),canActivate: [AuthenticationGuard],},

  { path: "users", loadChildren: () => import(`./users/users.module`).then((m) => m.UsersModule),canActivate: [AuthenticationGuard],},

  { path: "users-leave", loadChildren: () => import(`./users-leave/users-leave.module`).then((m) => m.UsersLeaveModule),canActivate: [AuthenticationGuard],},

  { path: "team-management", loadChildren: () => import(`./team-management/team-management.module`).then((m) => m.TeamManagementModule),canActivate: [AuthenticationGuard],},

  { path: "attendance", loadChildren: () => import(`./attendance/attendance.module`).then((m) => m.AttendanceModule),canActivate: [AuthenticationGuard],},
  
  { path: "extra-work", loadChildren: () => import(`./extra-work/extra-work.module`).then((m) => m.ExtraWorkModule),canActivate: [AuthenticationGuard],},

 


 


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

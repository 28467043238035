import { Component, OnInit } from "@angular/core";
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { Subscription } from "rxjs";
import { WebStorage } from "src/app/core/storage/web.storage";
import { MustMatch } from '../../app/pipes/must-match.validator';
import { NgxSpinnerService } from "ngx-spinner";
import { CommonService } from '../../app/services/common.service'
import { DataService } from '../../app/services/data.service';
import { MessageService } from '../../app/services/message.service'
import { ActivatedRoute, Router } from '@angular/router';
import { DataProviderService } from '../../app/services/data-provider.service'
import { ToastrService } from 'ngx-toastr';
import { AllModulesService } from "src/app/all-modules.service";
import { environment } from 'src/environments/environment';
import { HeaderService } from "./header.service";
import { BehaviorSubject, Observable } from 'rxjs';
declare const $: any;

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  jsonData: any = {
    notification: [],
    message: [],
  };
  notifications: any;
  messagesData: any;

  public userSubject: BehaviorSubject<any>
  public user: Observable<any>;

  isSubscribe: boolean= false

  subscribeSection: boolean= false;

  
  public allProfileDetails: any = [];
  public allProfileDetailsObj: any = [];
  name='';
  suburl=''

  constructor(private headerService: HeaderService, private srvModuleService: AllModulesService,  private toastr: ToastrService, private storage: WebStorage, private CommonService: CommonService, private messageService: MessageService, private dataProviderService: DataProviderService , private spinner: NgxSpinnerService, private route: ActivatedRoute, private router: Router
    
    ) {

      this.suburl= window.location.pathname


    this.userSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('LoginData')));
    console.log(this.userSubject.value.is_subscribed)
    this.isSubscribe= this.userSubject.value.is_subscribed;
    
    

      this.subscribeOpen()

      window.addEventListener('beforeunload', function (e) {
       // e.preventDefault();
       // e.returnValue = 'kkkkkkkkkkkkkk';
       // e.stopPropagation();
       //this.Logout();
  
     
    });


  }

  ngOnInit() {

    this.profileDetails()

    if(this.isSubscribe==false)
    {
      $("#subscription_info_popup").modal('show')
    }
    
    // this.getDatas("notification");
    // this.getDatas("message");

    this.notifications = [
      {
        message: "Patient appointment booking",
        author: "John Doe",
        function: "added new task",
        time: "4 mins ago",
      },
      {
        message: "Patient appointment booking",
        author: "John Doe",
        function: "added new task",
        time: "1 hour ago",
      },
      {
        message: "Patient appointment booking",
        author: "John Doe",
        function: "added new task",
        time: "4 mins ago",
      },
      {
        message: "Patient appointment booking",
        author: "John Doe",
        function: "added new task",
        time: "1 hour ago",
      },
      {
        message: "Patient appointment booking",
        author: "John Doe",
        function: "added new task",
        time: "4 mins ago",
      },
      {
        message: "Patient appointment booking",
        author: "John Doe",
        function: "added new task",
        time: "1 hour ago",
      },
    ];

    this.messagesData = [
      {
        message: "Lorem ipsum dolor sit amet, consectetur adipiscing",
        author: "Mike Litorus",
        time: "4 mins ago",
      },
      {
        message: "Lorem ipsum dolor sit amet, consectetur adipiscing",
        author: "Mike Litorus",
        time: "1 hour ago",
      },
      {
        message: "Lorem ipsum dolor sit amet, consectetur adipiscing",
        author: "Mike Litorus",
        time: "4 mins ago",
      },
      {
        message: "Lorem ipsum dolor sit amet, consectetur adipiscing",
        author: "Mike Litorus",
        time: "1 hour ago",
      },
      {
        message: "Lorem ipsum dolor sit amet, consectetur adipiscing",
        author: "Mike Litorus",
        time: "4 mins ago",
      },
      {
        message: "Lorem ipsum dolor sit amet, consectetur adipiscing",
        author: "Mike Litorus",
        time: "1 hour ago",
      },
    ];
  }


  profileDetails() {
    this.spinner.show();
    let url = environment.url.log_user_profile["profile-view"];
    
    this.CommonService.getAllData(url, '', '').subscribe((data) => {
      this.allProfileDetailsObj=data
      this.allProfileDetails = this.allProfileDetailsObj.data;

      console.log(this.allProfileDetails)
      this.spinner.hide();

      if(this.allProfileDetails.first_name== null && this.allProfileDetails.last_name== null)
      {
        this.name= this.allProfileDetails.email;
      }else {
        this.name= this.allProfileDetails.first_name+' '+this.allProfileDetails.last_name;
      }
      
    }, error=> {
      console.log('error',error.status)	
      this.spinner.hide();
      this.toastr.error(error.error.message, "Error");
      if(error.error.message==`You're not authorized.`)
      {
        this.router.navigate(['/login']);
      }
    });
  }

  subscribeOpen()
  {
    this.subscribeSection= true;
  }

  subscribeNow()
  {
    this.subscribeSection= false;
    $("#subscription_info_popup").modal("hide");

    
   // this.spinner.show();
   // let url = environment.url.org.subscribe;
   /* this.CommonService.postAllData('', url).subscribe((data)=>{	    
      console.log(data)
      this.spinner.hide();
      if(data['status']==200)
      {
        this.toastr.error(data['message'], "Error");
      }else   if(data['status']==201){ 
        

      var loginInfoString= JSON.stringify(JSON.parse(localStorage.getItem('LoginData')));
      let result = loginInfoString.replace(`"is_subscribed": false`, `"is_subscribed": true`);
      localStorage.setItem('LoginData', result);
      this.isSubscribe=true;
      this.messageService.sendMessage('subscription_update', 'subscription_update')
      this.toastr.success(data['message'], "Success");
      }
      $("#subscription_info_popup").modal("hide");

      }, error=> {
      console.log('error',error)	
      this.spinner.hide();
      this.toastr.error(error.error.message, "Error");
      $("#subscription_info_popup").modal("hide");
      }
  ); */




   


  }

  getDatas(section) {
    this.headerService.getDataFromJson(section).subscribe((data) => {
      this.jsonData[section] = data;
    });
  }

  clearData(section) {
    this.jsonData[section] = [];
  }
  onSubmit() {
    this.router.navigate(["/pages/search"]);
  }

  Logout(){

    if(localStorage.getItem('punchid')!=null)
    {
      this.spinner.show();
      let url = environment.url.Timesheet.punchout
      let objj = {
        punchid:  localStorage.getItem('punchid')
          };
  
  
      this.CommonService.putAllData(objj, url, '-1', 'view_p').subscribe((data1) => {
        this.spinner.hide();
        localStorage.removeItem('Currentseconds') 
        localStorage.removeItem('Currentminutes')
        localStorage.removeItem('Currenthour')
        localStorage.removeItem('punchid')

        localStorage.clear();

  
      }, error=> {
        console.log('error',error)	
        this.spinner.hide();
        this.toastr.error(error.error.message, "Error");
      });
  
    }
   


    localStorage.removeItem('loginSecond');
    localStorage.removeItem('loginMinutes');
    localStorage.removeItem('loginhour');
    
    localStorage.removeItem('LoginData')

    localStorage.clear();

    this.router.navigate(["/login"]);
  }
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  
  

  url: {
    apiBaseUrl : "https://api.payroll.karmickinfosystem.com/v1/api/", 
    globalSearchParameter: {
      sort_by: "id",
      sort_type: "desc",
      page_no: 1,
      per_page_rows: 10
    },

    org : {
          login: "org/signin",
          registration: "org/register",
          subscribe: "org/subscribe"

    },

    log_user_profile:{
      'profile-view': 'profile/view',
      'profile-update': 'profile/update',
      'profile-image-view': 'profile/get-profile-image',
      'profile-image-update': 'profile/upload-image',
    
    },
    
    admin:{
          login: "admin/signin",
          registration: "admin/signup"
    },

    user:{
          add: "employee/signup",
          update: "employee/update",
          delete: "employee/remove",
          list: "employee/list",
          allList: "employee/get-all-employee",
          emp_profile: 'profile/employee-profile',
          import: "upload/employee-bulk-import",
          
         // list: "company-employee/list",
          update_user_role: "employee/roles-update",
          role: "role/create",
          role_view: "role/view",
          role_list: "role/list",
          role_edit: "role/update",
          role_delete: "role/remove",
          get_module_manager: "role/module-manager",
          get_permission_by_role: "role/get-permission-by-role",
          save_role_permission: "role/permission",
          addEmpPersonal: "employee/employee-add-personal-details",
          editEmpPersonal: "employee/employee-edit-personal-details",

          empStatusList: "employee/get-employment-status",
          addEmpCompany: "employee/employee-add-company-info",
          addEmpDocument: "employee/employee-add-personal-documents",
          listEMpImage: "employee/get-employee-by-id",
          logInfo: "employee/create-employee-login-access",
          upDatelogInfo: "employee/update-employee-login-access"
      },

      company:{
        add: "company/create",
        edit: "company/update",
        delete: "company/remove",
        list: "company/list",
        view: "company/view",
        upload_logo: 'company/upload-logo',
        get_logo: 'company/get-logo',
        shift_add: "shift/create",
        shift_edit: "shift/update",
        shift_delete: "shift/remove",
        shift_list: "shift/list",
        shift_list_by_company: "shift/get-by-company",

        location: {
          add: "company-location/create",
          edit: "company-location/update",
          delete: "company-location/remove",
          list: "company-location/list",
          location_list_by_company: "company-location/get-by-company",
        },
        salary_slip: {
          add: "ssbd/create",
          edit: "ssbd/update",
          delete: "ssbd/remove",
          list: "ssbd/list",
          salary_list_by_company: "ssbd/get-by-company",
        },
        tax: {
          add: "tax/create",
          edit: "tax/update",
          delete: "tax/remove",
          list: "tax/list",
          tax_list_by_company: "tax/get-by-company",
        },
        appraisal_cycle: {
          add: "appraisal/create",
          edit: "appraisal/update",
          delete: "appraisal/remove",
          list: "appraisal/list",
          appraisal_cycle_list_by_company: "appraisal/get-by-company",
        },

        probation_cycle: {
          add: "probation/create",
          edit: "probation/update",
          delete: "probation/remove",
          list: "probation/list",
          probation_cycle_list_by_company: "probation/get-by-company",
        },
         company_employee: {
          add: "company-employee/create",
          edit: "company-employee/update",
          delete: "company-employee/remove",
          list: "company-employee/list",
          company_employee_list_by_company: "company-employee/get-by-company",
          company_emp_location: "company-employee-location/details"
        }, 
        leave_type: {
          add: "leavetype/create",
          edit: "leavetype/update",
          delete: "leavetype/remove",
          list: "leavetype/list",
          leave_type_list_by_company: "tax/get-by-company",
        },

          bank_details: {
          add: "bank-details/create",
          edit: "bank-details/update",
          delete: "bank-details/remove",
          list: "bank-details/list",
          employee : 'bank-details/employee-bank-details'
        
        },

        announcements: {
          add: "announcements/create",
          edit: "announcements/update",
          delete: "announcements/remove",
          list: "announcements/list",
        },

        paySlip: {
          edit_paySlip: 'payslip/edit',
          calculate_payable_salary : 'payslip/calculate-payable-salary',
          get_excel_data: 'payslip/get-excel-data',
          generate_payslip: 'payslip/generate-payslip',
          payslip_list: 'payslip/payslip-list',
          download_payslip:'payslip/download-payslip'
        }


      },

      salary_range:{

        add: "salary-range/create",
        edit: "salary-range/update",
        delete: "salary-range/remove",
        list: "salary-range/list"
      },

      monthly_roaster:{

        add: "roaster/create",
        edit: "roaster/update",
        view: "roaster/view",
        list: "roaster/list",
        calculate_salary: "roaster/calculate-salary",
        fileUpload: 'roaster/upload-file',
        delete_file: 'roaster/remove-roaster-file',
      },

      holidays:{

        add: "holiday/create",
        edit: "holiday/update",
        view: "holiday/view",
        list: "holiday/list",
        delete: "holiday/remove",
      },

      assign:{
        assign_senior: "assignment/assign-senior",
        get_unAssign_junior_list: "assignment/get-all-except-me",
        get_all_junior_list: "assignment/my-team-member",
        get_all_senior_list: "assignment/my-senior",
        delete: "assignment/remove-team-member"
      },

      leave: {
        apply: 'leave-request/make-leave-request',
        leave_list: 'leave-request/my-leaves',
        request_leave: 'leave-request/my-approval',
        approve_leave: 'leave-request/approve-request',
        all_senior_with_hr: 'leave-request/all-senior-with-hr',
        view_leave: 'leave-request/view-leaves',
        leave_in_hand: 'leave-request/leave-in-hand'
        
      },

      time_zone: {
        get_all_time_zone: 'timezone/all-time-zone'
      },

      Timesheet: {
        punchin: 'attendance/punchin',
        punchout: 'attendance/punchout',
        attendanceLog: 'attendance/log',
        calculate_total_time: 'attendance/calculate-total-time',
        calender_data: 'attendance/calender-data'

      }, 

      assign_extra_work: {

        add: "leave-request/create-special-leave",
        list_for_senior_end: "leave-request/created-special-leaves",
        list_for_junior_end: "leave-request/special-leaves"
      },

      over_time: {
        over_time_history: 'attendance/over-time-history',
        unapproved_over_time: 'attendance/unapprove-overtime',
        edit_over_time: 'attendance/edit-overtime'
      },

      attendance:
      {
        log: 'attendance/attendance-manager',
        update_attendance_status: 'attendance/manage-attendance',
        attendance_override_history: 'attendance/attendance-override-history',
        manage_attendance_list: 'attendance/attendance-manipulation',
        update_attendance_sheet: 'attendance/save-attendance-manipulation',
        attendance_manipulation_by_company: 'attendance/attendance-manipulation-by-company',
        save_attendance_data_individually: 'attendance/save-attendance-data-individually'

      },

      count: {
        average_login_time: 'dashboard/average-login-time',
        total_company: 'dashboard/total-company',
        total_employee :'dashboard/total-employee',
        monthly_total_absent: 'dashboard/monthly-total-absent',
        monthly_leave_applied:'dashboard/monthly-leave-applied',
        monthly_leave_approved:'dashboard/monthly-leave-approved',
        pending_leave_approval:'dashboard/pending-leave-approval',
        monthly_extra_work_days:'dashboard/monthly-extra-work-days',
        monthly_attendance_override:'dashboard/monthly-attendance-override',
        leave_in_hand: 'dashboard/leave-in-hand'

      }


    },

    
    firebase: {

      apiKey: "AIzaSyCuygH-MWOD3TgYWK5sDOaJQSlaVQsT8F4",
      authDomain: "hr-payroll-d747c.firebaseapp.com",
      projectId: "hr-payroll-d747c",
      storageBucket: "hr-payroll-d747c.appspot.com",
      messagingSenderId: "904040866358",
      appId: "1:904040866358:web:75c5068fd2b891198c6e97",
      measurementId: "G-X3MR5PDRN1",
      vapidKey :"BCrrtSNYzEblLPIQEXvbdc0XQeRKFDmChQwWOoukfAxFuxiBxJPtqr37PWGq8jFxTz_sPvGGUHUu2MiB83t3-C8",
      serverKey: "AAAA0n0I4jY:APA91bGKFg-sfuRiPWm5Gm0vIItouW2gnKZtt2IACvtpEwC92jLp_IWtm37EiByiNzZPvJLqpGeZljbmDqYY-vePnAY9JQYdBepVRVGmefYFRUfeuqgNgr-SiEMrxafygcd5f6joFytp"
    }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

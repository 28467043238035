import { Component, OnInit } from "@angular/core";
import { Router, Event, NavigationEnd } from "@angular/router";
import { AllModulesService } from "../all-modules.service";
import { BehaviorSubject, Observable } from 'rxjs';
import { MessageService } from '../../app/services/message.service'


@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  urlComplete = {
    mainUrl: "",
    subUrl: "",
    childUrl: "",
  };

  dashboardUrl = '';

  sidebarMenus = {
    default: true,
    company_list: false,
    settings: false,
  };

  members = {};
  groups = {};

  public userSubject: BehaviorSubject<any>
  public user: Observable<any>;

  isSubscribe: boolean = false;
  parameterId: any;

  menuArray = [

    { 'name': 'Employee', 'id': '', 'link': 'users', 'sub_link': '', 'icon': 'la la-user-plus' , 'hideShow': false,
      'menu': [
              { 'name': 'Role', 'id': 1, 'link': '/users/user-role', 'sub_link': 'user-role', 'icon': 'la la-gears', 'hideShow': false },
              { 'name': 'Employee', 'id': 2, 'link': '/users/user-main', 'sub_link': 'user-main', 'icon': 'la la-user-plus' , 'hideShow': false},
              ]
    },

    { 'name': 'Company', 'id': '', 'link': 'company', 'sub_link': '', 'icon': 'la la-group' , 'hideShow': false,
      'menu': [
               { 'name': 'Company', 'id': 3, 'link': '/company/company-list', 'sub_link': 'company-list', 'icon': 'la la-group' , 'hideShow': false},
               { 'name': 'Location', 'id': 4, 'link': '/company/location', 'sub_link': 'location', 'icon': 'la la-location-arrow' , 'hideShow': false},
               { 'name': 'Shift', 'id': 5, 'link': '/company/shift', 'sub_link': 'shift', 'icon': 'la la-user-times' , 'hideShow': false},
               { 'name': 'Team Assignment', 'id': 15, 'link': '/company/employee-list', 'sub_link': 'employee-list', 'icon': 'la la-graduation-cap' , 'hideShow': false},

               { 'name': 'Appraisal Cycle', 'id': 8, 'link': '/company/appraisal-cycle', 'sub_link': 'appraisal-cycle', 'icon': 'la la-dot-circle-o' , 'hideShow': false},
               { 'name': 'Probation Cycle', 'id': 9, 'link': '/company/probation-cycle', 'sub_link': 'probation-cycle', 'icon': 'la la-exclamation-circle' , 'hideShow': false},
               { 'name': 'Leave Type', 'id': 10, 'link': '/company/leave-type', 'sub_link': 'leave-type', 'icon': 'la la-toggle-off' , 'hideShow': false},
               { 'name': 'Bank Details', 'id': 11, 'link': '/company/bank-details', 'sub_link': 'bank-details', 'icon': 'la la-bank' , 'hideShow': false},
               { 'name': 'Holiday Calendar', 'id': 12, 'link': '/company/holidays', 'sub_link': 'holidays', 'icon': 'la la-toggle-off' , 'hideShow': false},
             
               { 'name': 'Announcement', 'id': 17, 'link': '/company/announcement', 'sub_link': 'announcement', 'icon': 'la la-newspaper-o' , 'hideShow': false},

               
              ]
    },

    { 'name': 'Salary', 'id': '', 'link': 'salary', 'sub_link': '', 'icon': 'la la-minus-square-o' , 'hideShow': false,
    'menu': [
            { 'name': 'Tax', 'id': 7, 'link': '/salary/tax', 'sub_link': 'tax', 'icon': 'la la-minus-square-o' , 'hideShow': false},
            { 'name': 'Salary Range', 'id': 13, 'link': '/salary/salary-range', 'sub_link': 'salary-range', 'icon': 'la la-money' , 'hideShow': false},
            { 'name': 'Monthly Roaster', 'id': 14, 'link': '/salary/monthly-roaster', 'sub_link': 'monthly-roaster', 'icon': 'la la-sort-amount-desc' , 'hideShow': false },
            { 'name': 'Pay Slip Generator', 'id': 18, 'link': '/salary/salary-sheet-view', 'sub_link': 'salary-sheet-view', 'icon': 'la la-sort-amount-asc' , 'hideShow': false },
            { 'name': 'Salary Break Down', 'id': 6, 'link': '/salary/salary-slip-break-down', 'sub_link': 'salary-slip-break-down', 'icon': 'la la-sort-amount-asc' , 'hideShow': false},

            ]
            
    },

    { 'name': 'Attendance', 'id': '16', 'link': 'attendance', 'sub_link': '', 'icon': 'la la-history' , 'hideShow': false,
          'menu': [
            
            { 'name': 'Attendance Log', 'id': '16', 'link': '/attendance/log', 'sub_link': 'log', 'icon': 'la la-history' , 'hideShow': false},
            { 'name': 'Manage Attendance', 'id': '16', 'link': '/attendance/manage', 'sub_link': 'manage', 'icon': 'la la-stack-overflow' , 'hideShow': false}


          ]
    },

   

  ]

  constructor(
    private router: Router,
    private allModulesService: AllModulesService, private messageService: MessageService
  ) {

    this.userSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('LoginData')));
    if(this.userSubject.value.is_primary== true)
    {
       
      this.menuArray.forEach((element)=>{
        element.hideShow= true
        if(element.id=='16')
        {
          element.hideShow= false
        }

      
        element.menu.forEach((element1)=>{
          element1.hideShow= true
            
          })
      })
    }
   else if(this.userSubject.value.permissionDetails.length!=0 && this.userSubject.value.permissionDetails.length>0)
    {
     
      this.menuArray.forEach((element)=>{

        if(element.menu.length>0)
        {
          element.menu.forEach(element2 => {
            this.userSubject.value.permissionDetails.forEach((element1)=>{
              if(element1.module_id==element2.id)
              {
                element.hideShow= true
                element2.hideShow= true
              }
  
  
            })
          });
        }else{

          this.userSubject.value.permissionDetails.forEach((element1)=>{
            if(element1.module_id==16)
            {
              element.hideShow= true
            }
          })
        }
      })
    }
   



  
console.log('this.menuArray', this.menuArray)

    this.isSubscribe = this.userSubject.value.is_subscribed;

    /*--------- Page load ----------- */
    const url = router.routerState.snapshot.url.split("/");
    this.urlComplete.mainUrl = url[1];
    this.urlComplete.subUrl = url[2];
    this.urlComplete.childUrl = url[3];

    if (this.userSubject.value.is_primary == true) {
      this.dashboardUrl = '/dashboard/admin';
    } else if (this.userSubject.value.user_type == 'employee') {
      this.dashboardUrl = '/dashboard/employee';
    }

    if (url[2] === "") {
      this.urlComplete.mainUrl = "dashboard";
      this.urlComplete.subUrl = "admin";
    }

    console.log('this.urlComplete',this.urlComplete)

    if (url[2] === "particular-company" || (url[2] === "shift" && typeof url[3] != 'undefined') || (url[2] === "location" && typeof url[3] != 'undefined') ||
      (url[2] === "salary-slip-break-down" && typeof url[3] != 'undefined') || (url[2] === "appraisal-cycle" && typeof url[3] != 'undefined') ||
      (url[2] === "tax" && typeof url[3] != 'undefined') ||

      (url[2] === "probation-cycle" && typeof url[3] != 'undefined') ||
      (url[2] === "company-employee" && typeof url[3] != 'undefined') ||
      (url[2] === "leave-type" && typeof url[3] != 'undefined') ||
      (url[2] === "user-main" && typeof url[3] != 'undefined') ||
      (url[2] === "bank-details" && typeof url[3] != 'undefined')
      ||
      (url[2] === "salary-range" && typeof url[3] != 'undefined')

      ||
      (url[2] === "monthly-roaster" && typeof url[3] != 'undefined')
      ||
      (url[2] === "holidays" && typeof url[3] != 'undefined')
      ||
      (url[2] === "announcement" && typeof url[3] != 'undefined')
    ) {

      this.parameterId = url[3]
      this.sidebarMenus.company_list = true;
      this.sidebarMenus.default = false;

    } else {
      this.parameterId = '';
      this.sidebarMenus.company_list = false;
      this.sidebarMenus.default = true;
    }

    /*--------- Page load ----------- */


    /*--------- Menu Click ----------- */
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        $(".main-wrapper").removeClass('slide-nav');
        $(".sidebar-overlay").removeClass('opened');
        const url = event.url.split("/");
        console.log(event.url)
        console.log(url)
        this.urlComplete.mainUrl = url[1];
        this.urlComplete.subUrl = url[2];
        this.urlComplete.childUrl = url[3];
        if (url[2] === "") {
          this.urlComplete.mainUrl = "dashboard";
          this.urlComplete.subUrl = "admin";
        }


        if (url[2] === "particular-company" || (url[2] === "shift" && typeof url[3] != 'undefined') || (url[2] === "location" && typeof url[3] != 'undefined') ||
          (url[2] === "salary-slip-break-down" && typeof url[3] != 'undefined') || (url[2] === "appraisal-cycle" && typeof url[3] != 'undefined') ||
          (url[2] === "tax" && typeof url[3] != 'undefined') ||

          (url[2] === "probation-cycle" && typeof url[3] != 'undefined') ||
          (url[2] === "company-employee" && typeof url[3] != 'undefined') ||
          (url[2] === "leave-type" && typeof url[3] != 'undefined') ||
          (url[2] === "user-main" && typeof url[3] != 'undefined') ||
          (url[2] === "bank-details" && typeof url[3] != 'undefined')
          ||
          (url[2] === "salary-range" && typeof url[3] != 'undefined')
          ||
          (url[2] === "monthly-roaster" && typeof url[3] != 'undefined')
          ||
          (url[2] === "holidays" && typeof url[3] != 'undefined')
          ||
          (url[2] === "announcement" && typeof url[3] != 'undefined')
        ) {
          this.parameterId = url[3]
          this.sidebarMenus.company_list = true;
          this.sidebarMenus.default = false;

        } else {
          this.parameterId = '';
          this.sidebarMenus.company_list = false;
          this.sidebarMenus.default = true;
        }
      }
    });

    /*--------- Menu Click ----------- */


    // console.log(this.router.routerState.snapshot.url);

    console.log(this.urlComplete)
    this.groups = { ...this.allModulesService.groups };
    this.members = { ...this.allModulesService.members };


    this.messageService.getMessage().subscribe(message => {
      if (message.event == 'subscription_update') {

        this.isSubscribe = true;

      }
    })



  }

  ngOnInit() {
    // Slide up and down of menus


    $('.hideShow').remove();

    $(document).on("click", "#sidebar-menu a", function (e) {

     

      e.stopImmediatePropagation();
      if ($(this).parent().hasClass("submenu")) {
        e.preventDefault();
      }
      if (!$(this).hasClass("subdrop")) {
        $("ul", $(this).parents("ul:first")).slideUp(350);
        $("a", $(this).parents("ul:first")).removeClass("subdrop");
        $(this).next("ul").slideDown(350);
        $(this).addClass("subdrop");
      } else if ($(this).hasClass("subdrop")) {
        $(this).removeClass("subdrop");
        $(this).next("ul").slideUp(350);
      }
    });

   
  }

  setActive(member) {
    this.allModulesService.members.active = member;
  }
}




import { Component, OnInit, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import { tap, catchError, map } from "rxjs/operators";
import { throwError, Observable } from "rxjs";

import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';




@Injectable({
  providedIn: 'root'
})


export class CommonService {
  
  getCurrentContributorUseInfo: any;
  httpOptionsSet: any;

  httpOptionsForfireBase: any;


 
 private userSubject: BehaviorSubject<any>
 public user: Observable<any>;

  constructor(private http: HttpClient,  private router: Router) {

  



  }



//.....set error message..........//
genarateErrorOrDataSet(error)
{
  var a = [];		
  
      if(typeof error === 'object')
      {
        for (var key in error) 
        {			
          if (error.hasOwnProperty(key)) 
            {			
              //a.push({key: key, val: error[key]});
              a[key]	= 	 error[key][0]	
            }			
        }	
        return a;	
      }else {
        return error;
      }
       		
       	
}
//.....set error message..........//



//.....set data array..........//
genarateDataSet(error)
{
  var a = [];		
  
      if(typeof error === 'object')
      {
        for (var key in error) 
        {			
          if (error.hasOwnProperty(key)) 
            {			
              //a.push({key: key, val: error[key]});
              a[key]	= 	 error[key]
            }			
        }	
        return a;	
      }else {
        return error;
      }
       		
       	
}
//.....set data message..........//


  // Handling Errors
  private handleError(error: any) {
    return throwError(error);
  }
  

//....post all data .............//
  postAllData (postAllvalue, apiHitUrl, module=null, action=null ) {

    const accessToken = localStorage.getItem("LoginData");
    if (accessToken) {
      this.httpOptionsSet = {
        headers: new HttpHeaders({
          'module':  module,
          'action': action,
          Authorization: `Bearer ${this.getToken()}`,
          'x-access-token': this.getToken()

        })
    };
    }else{

      headers: new HttpHeaders({
        'module':  module,
        'action': action,
       

      })

    }

   
      return this.http.post(environment.url.apiBaseUrl+apiHitUrl,postAllvalue,this.httpOptionsSet).pipe
      (
        res => res,  catchError(this.handleError)
       
      );
  }
//....End post data.............//


//....put all data .............//
putAllData (postAllvalue, apiHitUrl,  module=null, action=null ) {

  const accessToken = localStorage.getItem("LoginData");
    if (accessToken) {
      this.httpOptionsSet = {
        headers: new HttpHeaders({
          'module':  module,
          'action': action,
          Authorization: `Bearer ${this.getToken()}`,
          'x-access-token': this.getToken()

        })
    };
    }else{

      headers: new HttpHeaders({
        'module':  module,
        'action': action,
       

      })

    }


  

     return this.http.put(environment.url.apiBaseUrl+apiHitUrl,postAllvalue,  this.httpOptionsSet).pipe
     (
       res => res,  catchError(this.handleError)
      
     );
 }
//....End put data.............//


//....delete all data .............//

 deleteAllData ( apiHitUrl , module=null, action=null) {

  

    const accessToken = localStorage.getItem("LoginData");
    if (accessToken) {
      this.httpOptionsSet = {
        headers: new HttpHeaders({
          'module':  module,
          'action': action,
          Authorization: `Bearer ${this.getToken()}`,
          'x-access-token': this.getToken()

        })
    };
    }else{

      headers: new HttpHeaders({
        'module':  module,
        'action': action,
       

      })

    }

  return this.http.delete(environment.url.apiBaseUrl+apiHitUrl, this.httpOptionsSet).pipe
  (
    res => res,  catchError(this.handleError)
  );
}

//....End delete data.............//


//....get all data .............//
getAllData ( apiHitUrl ,  module=null, action=null) {


  const accessToken = localStorage.getItem("LoginData");
    if (accessToken) {
      this.httpOptionsSet = {
        headers: new HttpHeaders({
          'module':  module,
          'action': action,
          Authorization: `Bearer ${this.getToken()}`,
          'x-access-token': this.getToken()

        })
    };
    }else{

      headers: new HttpHeaders({
        'module':  module,
        'action': action,
       

      })

    }


 

  return this.http.get(environment.url.apiBaseUrl+apiHitUrl, this.httpOptionsSet).pipe
  (
    res => res,  catchError(this.handleError)
  );
}
//....End post data.............//



postImage(postAllvalue, apiHitUrl,  module=null, action=null)
{

  const accessToken = localStorage.getItem("LoginData");
    if (accessToken) {
      this.httpOptionsSet = {
        headers: new HttpHeaders({
          'module':  module,
          'action': action,
          Authorization: `Bearer ${this.getToken()}`,
          'x-access-token': this.getToken()

        })
    };
    }else{

      headers: new HttpHeaders({
        'module':  module,
        'action': action,
       

      })

    }


 
    return this.http.post(environment.url.apiBaseUrl+apiHitUrl,postAllvalue, this.httpOptionsSet).pipe
    (
      res => res,  catchError(this.handleError)
    );
}




getToken()
{

  console.log((localStorage.getItem('LoginData')))
  if((localStorage.getItem('LoginData')))
  {
    this.userSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('LoginData')));

    return this.userSubject.value.token
  }else {
    return ''
  }
 

}

permissionWisePageAccess(module_id)
{
    this.userSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('LoginData')));
    let permissionArray=[];

    permissionArray['create_p']= false;
    permissionArray['view_p']= false;
    permissionArray['edit_p']= false;
    permissionArray['delete_p']= false;

    if(this.userSubject.value.is_primary== true)
    {
      permissionArray['create_p']= true;
      permissionArray['view_p']= true;
      permissionArray['edit_p']= true;
      permissionArray['delete_p']= true;

     return  permissionArray

    }else if(this.userSubject.value.permissionDetails.length!=0 && this.userSubject.value.permissionDetails.length>0)
    {
          console.log(this.userSubject.value.permissionDetails)
          this.userSubject.value.permissionDetails.forEach((element, indx) => {

            if(element.module_id==module_id && element.create_p==true)
            {
              permissionArray['create_p']= true
            }

            if(element.module_id==module_id && element.view_p==true)
            {
              permissionArray['view_p']= true
            }

            if(element.module_id==module_id && element.edit_p==true)
            {
              permissionArray['edit_p']= true
            }

            if(element.module_id==module_id && element.delete_p==true)
            {
              permissionArray['delete_p']= true
            }

            
          });

          return   permissionArray
    }
}

checkViewPermission(view)
{
  if(view==false)
  {
    this.router.navigate(['/dashboard/admin']);

  }

}



fireBasePostApi(serverKey, postAllvalue)
{
  
    this.httpOptionsForfireBase = {
      headers: new HttpHeaders({
        'Authorization': 'key='+serverKey,
        'Content-Type': 'application/json'
        })
    };

    return this.http.post('https://fcm.googleapis.com/fcm/send',postAllvalue, this.httpOptionsForfireBase).pipe
    (
       res => res
    );
}





}









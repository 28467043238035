import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Event, Router, ActivatedRoute } from '@angular/router';


import { Validators, FormGroup, FormControl } from '@angular/forms';
import { Subscription } from "rxjs";
import { WebStorage } from "src/app/core/storage/web.storage";
import { NgxSpinnerService } from "ngx-spinner";
import { CommonService } from '../app/services/common.service'
import { DataService } from '../app/services/data.service';
import { MessageService } from '../app/services/message.service'
import { DataProviderService } from '../app/services/data-provider.service'
import { ToastrService } from 'ngx-toastr';
import { AllModulesService } from "src/app/all-modules.service";
import { environment } from 'src/environments/environment';
import { HeaderService } from "../app/header/header.service";
import { BehaviorSubject, Observable } from 'rxjs';
//import { MessagingService } from './services/messaging.service';

import { getMessaging, getToken, onMessage } from "firebase/messaging";


declare const $: any;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'HR-Payroll';
  url: string;
  leftHeader: boolean= false;

  onlyLeftNoHeader: boolean= false;


  private userSubject: BehaviorSubject<any>;
  public xxxObj: any = [];
  counter: { min: number, sec: number }

  message;
 
  cancel;
  intervalId;
  suburl=''
  
 
 /* @HostListener('document:mousemove', ['$event']) 
  onMouseMove(e) {
  //  window.location.reload();
  if(e.clientY=='' || e.clientY==0)
  {
    alert()
  }

 // console.log(e)

  } */
  pageReloaded;
  // Angular Example for beforeunload event handler
@HostListener('window:beforeunload', ['$event'])
beforeunloadHandler(event): void {
}

  constructor( private headerService: HeaderService, private srvModuleService: AllModulesService,  private toastr: ToastrService, private storage: WebStorage, private CommonService: CommonService, private messageService: MessageService, private dataProviderService: DataProviderService , private spinner: NgxSpinnerService, private route: ActivatedRoute, private router: Router){
    this.router.events.subscribe((event: Event) => {

      if (event instanceof NavigationEnd) {
        const url = event.url.split('/');
        this.url = url[1];
        if (
          event.url == '/' ||
          event.url == '/login' ||
          event.url == '/login/register' ||
          event.url == '/login/forgot' ||
          event.url == '/login/otp' ||
          event.url == '/login/lockscreen' || 
          event.url == '/demo' || 
          event.url == '/salary/salary-calculation' ||
          event.url == '/salary/salary-sheet-view' 

        ) {
          this.leftHeader= false;

          if ( event.url == '/salary/salary-sheet-view') 
          {
              this.onlyLeftNoHeader= true;
              document.querySelector('body').classList.remove('account-page');

          }else {
            document.querySelector('body').classList.add('account-page');

          }

        } else {
          this.leftHeader= true;
          document.querySelector('body').classList.remove('account-page');
        }
        if (
          event.url == '/error/error404' ||
          event.url == '/error/error500'
        ) {
          document.querySelector('body').classList.add('error-page');
        } else {
          document.querySelector('body').classList.remove('error-page');
        }
      }
    });

  this.suburl= window.location.pathname

   if(localStorage.getItem('LoginData') && (window.location.pathname=='/login' || window.location.pathname=='/')) 
   {
    this.router.navigate(['/dashboard/admin']);
   }

   $(window).on('beforeunload',function(e) {
    e = e || window.event;
    var localStorageTime  = parseInt(localStorage.getItem('storageTime'));
    if(localStorageTime!=null && localStorageTime!=undefined){
      var currentTime = new Date().getTime(),
          timeDifference = currentTime - localStorageTime;
  
      if(timeDifference<25){//Browser Closed
         localStorage.removeItem('storagetime');
         alert()
      }else{//Browser Tab Closed
         localStorage.setItem('storagetime',new Date().getTime().toString());
      }
  
    }else{
      localStorage.setItem('storagetime',new Date().getTime().toString());
    }
  });
    
   /*  window.addEventListener('beforeunload', function (e) {
      alert()
      e.preventDefault();
      console.log('@@@@@@@@@',e)

     // e.returnValue = 'kkkk';


     console.log(location.reload())
      if (window.performance) {
        console.info("window.performance works fine on this browser");
      }
      console.info(performance.navigation.type);
      if (performance.navigation.type == performance.navigation.TYPE_RELOAD) {
        console.info( "This page is reloaded" );
      } else {
        console.info( "This page is not reloaded");
      }
      
    });*/


   
  }
  ngOnInit() {
    //this.requestPermission();
    //this.listen(); 

    var inFormOrLink;

    $('a').on('click', function() { 
      inFormOrLink = true;
      console.log(inFormOrLink)
    });

    $('form').on('submit', function() { inFormOrLink = true; });
    $(window).on("beforeunload", function() { 
        //return inFormOrLink ? "Do you really want to close?" : null; 
    })



   this.counterPopUpStart();

   this.messageService.getMessage().subscribe(message => {
    if(message.event == 'punchInStart'){
      this.counterPopUpStart();
    }
    if(message.event == 'punchOutStart'){
      clearTimeout(this.cancel);
    }

   }) 

    $('#timer_div').modal({
      backdrop: 'static',
      keyboard: false
    }) 

    $("#timer_div").modal("hide");



    
    // Minified Sidebar

    $(document).on('click', '#toggle_btn', () => {
      if ($('body').hasClass('mini-sidebar')) {
        $('body').removeClass('mini-sidebar');
        $('.subdrop + ul').slideDown();
      } else {
        $('body').addClass('mini-sidebar');
        $('.subdrop + ul').slideUp();
      }
      return false;
    });

    $(document).on('mouseover', (e) => {
      e.stopPropagation();
      if ($('body').hasClass('mini-sidebar') && $('#toggle_btn').is(':visible')) {
        const targ = $(e.target).closest('.sidebar').length;
        if (targ) {
          $('body').addClass('expand-menu');
          $('.subdrop + ul').slideDown();
        } else {
          $('body').removeClass('expand-menu');
          $('.subdrop + ul').slideUp();
        }
        return false;
      }
    });

    $('body').append('<div class="sidebar-overlay"></div>');
    $(document).on('click', '#mobile_btn', function() {
      var $wrapper = $('.main-wrapper');
      $wrapper.toggleClass('slide-nav');
      $('.sidebar-overlay').toggleClass('opened');
      $('html').addClass('menu-opened');
      $('#task_window').removeClass('opened');
      return false;
    });
    
    $(".sidebar-overlay").on("click", function () {
      var $wrapper = $('.main-wrapper');
        $('html').removeClass('menu-opened');
        $(this).removeClass('opened');
        $wrapper.removeClass('slide-nav');
        $('.sidebar-overlay').removeClass('opened');
        $('#task_window').removeClass('opened');
    });
  }

  

  counterPopUpStart()
  {
      
   if(localStorage.getItem('punchid')!=null)
   {
    //clearTimeout(this.cancel);
    this.cancel = setInterval(()=>{
      var TotalNowDiffSecond = 0;

      var StoreloginSecond = localStorage.getItem('loginSecond') 
      var StoreloginMinutes = localStorage.getItem('loginMinutes')
      var Storeloginhour = localStorage.getItem('loginhour')

      var TotalStoreSecond= parseInt(Storeloginhour)*3600 + parseInt(StoreloginMinutes)*60 + parseInt(StoreloginSecond)

      var date = new Date;
      var NowCurrentseconds = date.getSeconds();
      var NowCurrentminutes = date.getMinutes();
      var NowCurrenthour = date.getHours();

    var TotalNowCurrentSecond= (NowCurrenthour)*3600 + (NowCurrentminutes)*60 + (NowCurrentseconds)

     TotalNowDiffSecond= TotalNowCurrentSecond - TotalStoreSecond

    
    console.log('TotalNowDiffSecond',TotalNowDiffSecond)
     
      if(TotalNowDiffSecond==3600)
    // if(TotalNowDiffSecond==60)
      {
        $("#timer_div").modal("show");
        this.startTimer()
        this.requestPermission();
        this.listen();
  
      }

      if(TotalNowDiffSecond>4000)
     // if(TotalNowDiffSecond>130)
      {
        $("#timer_div").modal("hide");
        clearTimeout(this.cancel);
        clearInterval(this.intervalId);
        this.noF()
      }
   
    }, 1000);
   }
    
  }

  
    startTimer() {
      this.counter = { min: 5, sec: 0 } // choose whatever you want
     // this.counter = { min: 1, sec: 0 } // choose whatever you want

      this.intervalId = setInterval(() => {
        if (this.counter.sec - 1 == -1) {
          this.counter.min -= 1;
          this.counter.sec = 59
        } 
        else this.counter.sec -= 1
        if (this.counter.min === 0 && this.counter.sec == 0) {
          $("#timer_div").modal("hide");
          clearTimeout(this.cancel);
          clearInterval(this.intervalId);
          this.noF()
         

        }
        
      }, 1000)
    }



  yesF() {

    clearInterval(this.intervalId);
    clearTimeout(this.cancel);
    $("#timer_div").modal("hide");
    //clearTimeout(this.cancel);
  
    
    this.messageService.sendMessage( 'stillWorking', 'stillWorking');


  }

  noF()
  {

   
    if(localStorage.getItem('punchid')!=null)
    {

      this.spinner.show();
      let url = environment.url.Timesheet.punchout
      let objj = {
        punchid:  localStorage.getItem('punchid'),
      //  is_alert: 1
          };
  
  
      this.CommonService.putAllData(objj, url, '-1', 'view_p').subscribe((data1) => {
       
        this.xxxObj = data1
        this.spinner.hide();
        this.messageService.sendMessage( 'punchOutForNowork', 'punchOutForNowork');
        localStorage.removeItem('Currentseconds') 
        localStorage.removeItem('Currentminutes')
        localStorage.removeItem('Currenthour')
        localStorage.removeItem('punchid')

        $("#timer_div").modal("hide");

        localStorage.removeItem('loginSecond');
        localStorage.removeItem('loginMinutes');
        localStorage.removeItem('loginhour');

        clearTimeout(this.cancel);

        this.toastr.success(this.xxxObj.message, "Success");
        //this.router.navigate(['/dashboard/admin']);

       // window.location.href = '/dashboard/admin'
      
  
      }, error=> {
        console.log('error',error)	
        this.spinner.hide();
        this.toastr.error(error.error.message, "Error");
      });
  
    }

  }



  logOut()
  {

    if(localStorage.getItem('punchid')!=null)
    {

    
      this.spinner.show();
      let url = environment.url.Timesheet.punchout
      let objj = {
        punchid:  localStorage.getItem('punchid')
          };
  
  
      this.CommonService.putAllData(objj, url, '-1', 'view_p').subscribe((data1) => {
        this.spinner.hide();
        localStorage.removeItem('Currentseconds') 
        localStorage.removeItem('Currentminutes')
        localStorage.removeItem('Currenthour')
        localStorage.removeItem('punchid')

        localStorage.removeItem('loginSecond');
        localStorage.removeItem('loginMinutes');
        localStorage.removeItem('loginhour');
    
  
      }, error=> {
        console.log('error',error)	
        this.spinner.hide();
        this.toastr.error(error.error.message, "Error");
      });
  
    }
   
    
    localStorage.removeItem('LoginData')
  }





  requestPermission() {
    const messaging = getMessaging();
    getToken(messaging, 
     { vapidKey: environment.firebase.vapidKey}).then(
       (currentToken) => {
         if (currentToken) {
           console.log("Hurraaa!!! we got the token.....");
           console.log(currentToken);

           let serverKey= environment.firebase.serverKey;
            let postAllvalue=
                    {
                            "notification": {
                            "title":"Hey there", 
                            "body": "Your punching attendance session is timing out. Please click here https://payroll.karmickinfosystem.com/dashboard/admin to visit the application within 1 hour to continue the session."
                           
                          },
                          
                          "to":currentToken
                          
                   }

                  /* "body":"Are you still working? If did then please visit our site at least one time with in every hour otherwise we did punchout" */

                   this.CommonService.fireBasePostApi(serverKey, postAllvalue).subscribe((data1) => {
       
                    console.log('data1',data1)
            
              
                  }, error=> {
                    console.log('error',error)	
                    
                  });

                  
                 



         } else {
           console.log('No registration token available. Request permission to generate one.');
         }
     }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
    });
  }
  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      this.message=payload;
    });
  }



}

import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { BehaviorSubject } from "rxjs"; 

@Injectable({
  providedIn: 'root'
})
export class DataProviderService {
	
	private dataSource = new BehaviorSubject("Pabitra");
	public serviceData = this.dataSource.asObservable();
	
  constructor() { }

	changeData(data: any) {
		this.dataSource.next(data);
    }

}
